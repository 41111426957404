<template>
  <div class="dp-brew-history">
    <div v-if="!contents || !contents.length" class="dp-brew-history-empty">
      <div class="dp-brew-history-empty__section">
        <DpEmpty
          icon-name="dpHistoryR"
          :text="WORDS.DRIP_POD_HISTORY_LIST.EMPTY_TEXT"
          :subtext="WORDS.DRIP_POD_HISTORY_LIST.EMPTY_SUBTEXT" />
      </div>

      <div class="dp-brew-history-empty__button">
        <DpButton to="/drip-pod/pro-recipe/" style-type="secondary" fluid>
          {{ WORDS.DRIP_POD_HISTORY_LIST.MORE }}
        </DpButton>
      </div>
    </div>

    <template v-else>
      <DpPageLoading v-if="isLoading" :is-loading="isLoading" />

      <DripPodHistoryCard
        v-for="content in contents"
        :key="content.id"
        :content="content"
        @on-img-loaded="onCardImgLoaded" />
    </template>

    <p
      v-if="!isLoading && contents && contents.length"
      class="dp-brew-history__description">
      {{ WORDS.DRIP_POD_HISTORY_LIST.DESCRIPTION }}
    </p>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    DripPodHistoryCard: () =>
      import('@/components/dripPod/brew/HistoryCard.vue'),

    DpButton: () => import('@/components/dripPod/core/Button.vue'),
    DpPageLoading: () => import('@/components/dripPod/core/PageLoading.vue'),
    DpEmpty: () => import('@/components/dripPod/core/Empty.vue')
  },

  props: {
    contents: {
      type: Array,
      default: undefined
    }
  },

  setup: (props) => {
    const loadedImgCount = ref(0);
    const isLoading = computed(
      () => loadedImgCount.value !== props.contents.length
    );
    const onCardImgLoaded = () => {
      loadedImgCount.value++;
    };

    return {
      WORDS,
      onCardImgLoaded,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-brew-history {
  @include mixin.dpDefaultText;

  &__description {
    margin-bottom: 0;
    padding: 16px;
  }
}

.dp-brew-history-empty {
  margin: 0 16px;
  padding: 48px 0;

  &__section {
    margin-bottom: 48px;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-brew-history" },
    [
      !_vm.contents || !_vm.contents.length
        ? _c("div", { staticClass: "dp-brew-history-empty" }, [
            _c(
              "div",
              { staticClass: "dp-brew-history-empty__section" },
              [
                _c("DpEmpty", {
                  attrs: {
                    "icon-name": "dpHistoryR",
                    text: _vm.WORDS.DRIP_POD_HISTORY_LIST.EMPTY_TEXT,
                    subtext: _vm.WORDS.DRIP_POD_HISTORY_LIST.EMPTY_SUBTEXT,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dp-brew-history-empty__button" },
              [
                _c(
                  "DpButton",
                  {
                    attrs: {
                      to: "/drip-pod/pro-recipe/",
                      "style-type": "secondary",
                      fluid: "",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.WORDS.DRIP_POD_HISTORY_LIST.MORE) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : [
            _vm.isLoading
              ? _c("DpPageLoading", { attrs: { "is-loading": _vm.isLoading } })
              : _vm._e(),
            _vm._l(_vm.contents, function (content) {
              return _c("DripPodHistoryCard", {
                key: content.id,
                attrs: { content: content },
                on: { "on-img-loaded": _vm.onCardImgLoaded },
              })
            }),
          ],
      !_vm.isLoading && _vm.contents && _vm.contents.length
        ? _c("p", { staticClass: "dp-brew-history__description" }, [
            _vm._v(
              " " + _vm._s(_vm.WORDS.DRIP_POD_HISTORY_LIST.DESCRIPTION) + " "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }